import { defineStore } from 'pinia';

interface represesData {
  payload: {
    agency: {
      city: string;
      type: string;
      inn: string;
      name: string;
    };
  };

  'company_files'?: File | null;
  fileCompanyCard?: File | null;
  fileProcuratory?: File | null;
  fileCharter?: File | null;
  fileOgrn?: File | null;
  fileInn?: File | null;
  fileOgrnip?: File | null;
}

interface AgentData {
  'name': string;
  'surname': string;
  'patronymic': string;
  'phone': string;
  'password_1': string;
  'password_2': string;
  'email': string;
  'is_contracted': boolean;
  'agency': number;
}

const Errors = {
  TYPICAL: 'Произошла ошибка',
  SERVER: 'Ошибка на сервере'
};

export const useRegistrationStore = defineStore('registration', {
  state: () => ({
    represesData: {},
    agentData: {},
    personalData: {}
  }),

  actions: {
    setRepresesData (payload: represesData): void {
      this.represesData = payload;
    },

    setAgentData (payload: AgentData): void {
      this.agentData = payload;
    },

    setPersonalData (payload: AgentData): void {
      this.personalData = payload;
    },

    resetRegistrationData (): void {
      this.represesData = {};
      this.agentData = {};
      this.personalData = {};
    },

    async checkUnique ({
      email,
      phone,
      role
    }: {
      email: string;
      phone: string;
      role: string;
    }): Promise<void> {
      try {
        const url = this.$api.account.checkContacts;
        await this.$axios.$post(url, {
          email,
          phone,
          role
        });
      } catch (error: unknown) {
        console.error('[registration/checkUnique]: checkUnique failed', error);
        this.$sentry.captureException(error);
        throw error;
      }
    },

    async represRegistration (payload: FormData) {
      try {
        const url = this.$api.account.register('represes');

        const {
          agency
        } = await this.$axios.$post(url, payload, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (!agency?.id) {
          throw new Error(Errors.SERVER);
        }
      } catch (error) {
        console.error('[registration/represRegistration]: registration failed', error);
        this.$sentry.captureException(error);
        throw error;
      }
    },

    async agentRegistration (agencyId: string) {
      try {
        const url = this.$api.account.register('agents');
        const {
          id
        } = await this.$axios.$post(url, {
          agency: agencyId,
          ...this.personalData,
          is_contracted: true
        });

        if (!id) {
          throw new Error(Errors.SERVER);
        }
      } catch (error) {
        console.error('[registration/agentRegistration]: registration failed', error);
        this.$sentry.captureException(error);
        throw error;
      }
    }
  }
});
