import { defineStore } from 'pinia';
import {
  computed,
  ref
} from 'vue';
import { useContext } from '@nuxtjs/composition-api';
import type {
  CityResponse,
  CitySelectType
} from '@/types/Cities';

export const useCitiesStore = defineStore('cities', () => {
  const cities = ref<Array<CitySelectType>>([]);
  const { $axios, $sentry } = useContext();

  const getCityById = computed(() => (cityId: number): CitySelectType | undefined => {
    if (!cities.value) {
      return;
    }

    return cities.value.find(({ id }) => id === cityId);
  });

  async function getCities (): Promise<void> {
    try {
      const {
        data
      } = await $axios.get<Array<CityResponse>>('api/cities');

      cities.value = data.map(({
        id,
        name,
        slug
      }) => ({
        id,
        text: name,
        value: id,
        citySlug: slug
      }));
    } catch (error) {
      console.error('🚀 ~ file: cities.ts ~ getCities ~ e', error);
      $sentry.captureException(error);
    }
  }

  return {
    getCityById,
    cities,
    getCities
  };
});
