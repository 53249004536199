import { goTo } from '~goTo/goTo';

export default async function (to, from, savedPosition) {
  if (to.meta.disableScrollOnRouteChange) {
    return;
  }

  if (to.hash) {
    const positionY = await goTo(to.hash, {
      duration: 1000
    });

    return {
      x: 0,
      y: positionY
    };
  } else {
    return {
      x: 0,
      y: 0
    };
  }
}
