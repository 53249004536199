import { defineStore } from 'pinia';
import {
  computed,
  ref
} from 'vue';
import { useContext } from '@nuxtjs/composition-api';
import {
  Building,
  BuildingResponse
} from './types';
import { useProjectsStore } from '@/store/projects';

export const PROJECT_KINDS = {
  RESIDENTIAL: 'RESIDENTIAL',
  PARKING: 'PARKING',
  OFFICE: 'OFFICE'
} as const;

export const useBuildingsStore = defineStore('building', () => {
  const {
    $axios,
    $api,
    $sentry
  } = useContext();
  const buildings = ref<Array<Building> | undefined>(undefined);

  const buildingsWithFlats = computed(() => {
    if (!buildings.value) {
      return;
    }

    return buildings.value.filter(({ flatsCount }) => flatsCount);
  });

  const specsBuildings = computed(() => {
    return buildingsWithFlats.value?.map(({ title, id }) => ({
      label: title,
      value: id
    }));
  });

  const getBuildingById = computed(() => (buildingId: number): Building | undefined => {
    return buildingsWithFlats.value?.find(({ id }) => id === Number(buildingId));
  });

  async function getBuildings (projectId: number): Promise<void> {
    try {
      const { getProjectById } = useProjectsStore();
      const project = getProjectById(projectId);

      const url = $api.buildings.list;
      const { data } = await $axios.get<Array<BuildingResponse>>(url, {
        params: {
          project: project?.slug,
          kind: [PROJECT_KINDS.RESIDENTIAL]
        }
      });

      buildings.value = data?.length
        ? data.map((building: BuildingResponse) => ({
          ...building,
          title: building.nameDisplay ?? building.name
        }))
        : [];
    } catch (error) {
      console.error('🚀 ~ file: buildings.ts ~ getBuildings ~ error', error);
      $sentry.captureException(error);
      throw (error);
    }
  }

  function reset (): void {
    buildings.value = undefined;
  }

  return {
    buildings,
    buildingsWithFlats,
    specsBuildings,
    getBuildingById,
    getBuildings,
    reset
  };
});
