import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { Context } from '@nuxt/types';

let nuxtApp: Context | undefined;

export default defineNuxtPlugin((_nuxtApp: Context) => {
  nuxtApp = _nuxtApp;
});

export const useVuexStore = (): NonNullable<typeof nuxtApp>['store'] => {
  if (!nuxtApp?.store) {
    throw new Error('Vuex Store is not defined :(');
  }

  return nuxtApp?.store;
};
