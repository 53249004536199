var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(_vm.component.is, _vm._b({
    tag: "Component",
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.handleClose.apply(null, arguments);
      }
    },
    model: {
      value: _vm.isShow,
      callback: function callback($$v) {
        _vm.isShow = $$v;
      },
      expression: "isShow"
    }
  }, 'Component', _vm.component.bind, false), [_c('div', {
    class: _vm.$style.wrapper,
    on: {
      "contextmenu": function contextmenu($event) {
        $event.stopPropagation();
        $event.preventDefault();
      }
    }
  }, [_vm.isMobile ? _c('div', {
    class: _vm.$style.headingWrapper
  }, [_c('h3', {
    class: _vm.$style.heading
  }, [_c('span', {
    class: _vm.$style.headingInner
  }, [_vm._v(_vm._s(_vm.data.heading))]), _vm._v(" "), _vm.headingName ? _c('span', {
    staticClass: "vice--text",
    class: _vm.$style.headingName
  }, [_vm._v("\n          " + _vm._s(_vm.headingName) + "\n        ")]) : _vm._e()]), _vm._v(" "), _c('SButton', {
    class: _vm.$style.closeButton,
    attrs: {
      "size": "small",
      "theme": "ghost",
      "equal": "",
      "rounded": ""
    },
    on: {
      "click": _vm.handleClose
    }
  }, [_c('SIcon', {
    attrs: {
      "size": "24",
      "icon": "i-close"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c('VList', {
    class: _vm.$style.list
  }, _vm._l(_vm.$contextMenu.data.items, function (item) {
    return _c('VListItem', {
      key: item.value,
      class: _vm.$style.item,
      attrs: {
        "disabled": item.disabled
      },
      on: {
        "click": function click($event) {
          return _vm.$emit(item.value);
        }
      }
    }, [_vm.isMobile ? _c('VListItemIcon', {
      class: _vm.$style.iconWrapper
    }, [_c('div', {
      class: _vm.$style.iconInner
    }, [_c('SIcon', {
      attrs: {
        "size": "24",
        "icon": "i-".concat(item.icon)
      }
    })], 1)]) : _vm._e(), _vm._v(" "), _c('VListItemTitle', [_vm._v(_vm._s(item.title))])], 1);
  }), 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };