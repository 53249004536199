import { defineStore } from 'pinia';
import {
  ref,
  useContext
} from '@nuxtjs/composition-api';
import {
  MeetingResponse
} from '@/types/Meeting';

type StagesKeys =
  'MEETING_SIGN_UP' |
  'MEETING_CANCEL' |
  'MEETING_RESCHEDULE' |
  'MEETING_ACTION_NOTIFICATION';

type Stage = {
  // eslint-disable-next-line no-unused-vars
  [_key in StagesKeys]: string;
}

const STAGES: Stage = {
  MEETING_SIGN_UP: 'MeetingSignUp',
  MEETING_CANCEL: 'MeetingCancel',
  MEETING_RESCHEDULE: 'MeetingReschedule',
  MEETING_ACTION_NOTIFICATION: 'MeetingActionNotification'
};

interface MeetingStatuses {
  sort: number;
  label: string;
  value: string;
}

interface MeetingsResponse {
  count: number;
  recordCount: number;
  result: Array<MeetingResponse>;
}

interface Slots {
  date: string;
  times: Array<string>;
}

interface State {
  stateMeetingAction?: string;
  meetings: Array<MeetingsResponse>;
  meeting?: MeetingResponse;
  slots: Array<Slots>;
  statuses: Array<MeetingStatuses>;
}

interface Form {
  city: string;
  project: string;
  roomType: string;
  meetingType: string;
  date: string;
  time: string;
}

export const useMeetingsStore = defineStore('meetings', () => {
  const meetings = ref<State>({
    stateMeetingAction: undefined,
    meetings: [],
    meeting: undefined,
    slots: [],
    statuses: []
  });
  const {
    $axios,
    $sentry
  } = useContext();
  async function getMeetingStatuses (): Promise<void> {
    try {
      const url: string = 'api/meetings/statuses';
      const {
        data
      } = await $axios.get<Array<MeetingStatuses>>(url);

      meetings.value.statuses = data;
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ getMeetingStatuses ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function getMeeting (meetingId: string): Promise<void> {
    try {
      const url: string = `api/meetings/${ meetingId }`;
      const {
        data
      } = await $axios.get<MeetingResponse>(url);

      meetings.value.meeting = data;
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ getMeeting ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function getSlots ({
    meet,
    city,
    project
  }: {
    meet: string;
    city: string;
    project: string;
  }): Promise<void> {
    try {
      const url: string = 'api/meetings/slots';
      const {
        data
      } = await $axios.get(url, {
        params: {
          meet,
          city,
          project
        }
      });

      meetings.value.slots = data;
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ getSlots ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function signUpMeeting ({
    form,
    bookingId
  }: {
    form: Form;
    bookingId: string;
  }): Promise<void> {
    try {
      const {
        city,
        project,
        meetingType,
        date,
        time,
        roomType
      } = form;

      const {
        data
      } = await $axios.post('api/meetings', {
        cityId: city,
        projectId: project,
        type: meetingType,
        topic: '',
        date: `${ date }T${ time }Z`,
        propertyType: roomType,
        bookingId: Number(bookingId)
      });

      await getMeeting(data.id);
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ signUpMeeting ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function rescheduleMeeting ({
    form,
    meetingId
  }: {
    form: Form;
    meetingId: string;
  }): Promise<void> {
    try {
      const {
        city,
        project,
        meetingType,
        date,
        time
      } = form;

      await $axios.patch(`api/meetings/${ meetingId }`, {
        cityId: city,
        projectId: project,
        type: meetingType,
        topic: '',
        date: `${ date }T${ time }Z`
      });

      await getMeeting(meetingId);
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ rescheduleMeeting ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function cancelMeeting (meetingId: string): Promise<void> {
    try {
      await $axios.patch(`api/meetings/${ meetingId }/refuse`);
      await getMeeting(meetingId);
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ cancelMeeting ~ error', error);
      $sentry.captureException(error);
    }
  }

  function setStage (stage: StagesKeys): void {
    meetings.value.stateMeetingAction = STAGES[stage];
  }

  return {
    meetings,
    getMeetingStatuses,
    getMeeting,
    getSlots,
    signUpMeeting,
    rescheduleMeeting,
    cancelMeeting,
    setStage
  };
}
);
