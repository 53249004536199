import { LocalScheme } from '@nuxtjs/auth-next/dist/runtime';
import { DataLayerService } from '@@/shared/services/DataLayerService';

export default class EmailScheme extends LocalScheme {
  async login (endpoint, {
    reset = true
  } = {}) {
    const response = await super.login(endpoint, {
      reset
    });
    const {
      data
    } = response;

    const roleBackend = {
      agent: 'agents',
      repres: 'represes'
    };

    if (!roleBackend[data.role]) {
      return;
    }

    await this.$auth.setStrategy(roleBackend[data.role]);
    await this.$auth.setUserToken(data.token);
    await this.$auth.fetchUser();

    const dataLayer = new DataLayerService();
    const strategy = this.$auth.$state.strategy;

    const roleSEO = {
      agents: 'agent',
      represes: 'agency',
      users: 'user'
    };

    dataLayer.setUserId(data.id);
    dataLayer.addEvent('login', {
      role: roleSEO[strategy]
    });

    return response;
  }

  async logout (endpoint) {
    const response = await super.logout(endpoint);

    if (process.env.DEVELOPMENT) {
      this.$auth.$storage.removeCookie('_lksession', {
        prefix: '',
        path: '/',
        domain: ''
      });
    }

    return response;
  }
}
