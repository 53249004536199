import {
  clearBodyLocks,
  lock,
  unlock
} from 'tua-body-scroll-lock';
import { useMemoize } from '@vueuse/core';
import {
  iosScrollLockedAttributeName,
  iosScrollLockedOffsetCssProp,
  iosScrollShouldLockAttributeName
} from '../constants/technical';

type TargetElements = HTMLElement | Array<HTMLElement>

const lockedMap = new Map<HTMLElement, number>();

function lockElement (element: HTMLElement): void {
  if (element.hasAttribute(iosScrollShouldLockAttributeName)) {
    if (!element.hasAttribute(iosScrollLockedAttributeName)) {
      const scrollTop = element.scrollTop;
      element.setAttribute(iosScrollLockedAttributeName, scrollTop.toString());
      element.style.setProperty(iosScrollLockedOffsetCssProp, `${ scrollTop }px`);
    }
    const alreadyLockedTimes = lockedMap.get(element) ?? 0;
    lockedMap.set(element, alreadyLockedTimes + 1);
  }
}

function lockAllParents (element: HTMLElement): void {
  // лочить родителей будем в обратном порядке: от деда к правнуку - потому что в прямом порядке
  // scrollTop-ы теряются
  const parentsStraight: Array<HTMLElement> = [];
  for (let parent = element.parentElement; parent != null; parent = parent.parentElement) {
    parentsStraight.push(parent);
  }

  for (let i = parentsStraight.length - 1; i >= 0; i--) {
    lockElement(parentsStraight[i]);
  }

  // костыль для html/body
  // объяснить не могу, на html почему-то не действуют top: -scrollTop или margin-top: -scrollTop
  const htmlScrollTop = Number(document.documentElement.getAttribute(iosScrollLockedAttributeName));
  const bodyScrollTop = Number(document.body.getAttribute(iosScrollLockedAttributeName));

  if (htmlScrollTop > 0 && bodyScrollTop === 0) {
    document.body.setAttribute(iosScrollLockedAttributeName, htmlScrollTop.toString());
    document.body.style.setProperty(iosScrollLockedOffsetCssProp, `${ htmlScrollTop }px`);
  }
}

function unlockElement (element: HTMLElement): void {
  if (!lockedMap.has(element)) {
    return;
  }

  const alreadyLockedTimes = lockedMap.get(element) ?? 0;

  if (alreadyLockedTimes > 1) {
    lockedMap.set(element, alreadyLockedTimes - 1);
  } else {
    lockedMap.delete(element);
    const scrollTop = Number(element.getAttribute(iosScrollLockedAttributeName));
    element.removeAttribute(iosScrollLockedAttributeName);
    element.style.removeProperty(iosScrollLockedOffsetCssProp);

    if (!isNaN(scrollTop)) {
      element.scrollTop = scrollTop;
    }
  }
}

function unlockAllParents (element: HTMLElement): void {
  for (let parent = element.parentElement; parent != null; parent = parent.parentElement) {
    unlockElement(parent);
  }
}

function forceUnlockAll (): void {
  for (const [element] of lockedMap) {
    element.classList.remove(iosScrollLockedAttributeName);
  }
  lockedMap.clear();
}

const isIOS = useMemoize((): boolean => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
});

class PageScrollService {
  private lockCount: number = 0

  lock (elements?: TargetElements): void {
    if (this.isUnlocked()) {
      this.setScrollOffset(this.getScrollbarWidth());
    }

    this.lockCount += 1;


    // TODO хорошо проверить в не iOS - по идее должно работать везде
    if (isIOS()) {
      const elementsToLock = Array.isArray(elements) ? elements : (elements == null ? [] : [elements]);
      for (const x of elementsToLock) {
        lockAllParents(x);
      }
    } else {
      lock(elements);
    }
  }

  unlock (elements?: TargetElements): void {
    this.lockCount = Math.max(0, this.lockCount - 1);

    if (this.isUnlocked()) {
      this.setScrollOffset(0);
    }

    if (isIOS()) {
      const elementsToUnlock = Array.isArray(elements) ? elements : (elements == null ? [] : [elements]);
      for (const x of elementsToUnlock) {
        unlockAllParents(x);
      }
    } else {
      unlock(elements);
    }
  }

  forceUnlock (): void {
    this.lockCount = 0;
    this.setScrollOffset(0);
    clearBodyLocks();

    if (isIOS()) {
      forceUnlockAll();
    }
  }

  private setScrollOffset (offset: number): void {
    if (!process.client) {
      return;
    }

    document.documentElement.style.setProperty('--scroll-lock-offset', `${ offset }px`);
  }

  private isUnlocked (): boolean {
    return this.lockCount === 0;
  }

  private getScrollbarWidth (): number {
    if (!process.client) {
      return 0;
    }

    const documentWidth = document.documentElement.clientWidth;
    const windowsWidth = window.innerWidth;

    return windowsWidth - documentWidth;
  }
}

export const pageScrollService = new PageScrollService();
