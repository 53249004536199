// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LandingLayout_qEfn3{display:-webkit-box;display:-ms-flexbox;display:flex;height:100%;min-height:100%}.LandingLayout_qEfn3,.pageWrapper_gnNzw{position:relative;width:100%}.pageWrapper_gnNzw{padding-top:var(--header-h);-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}.pageWrapper_gnNzw.fullHeight_d61-\\+{height:100%}.pageWrapper_gnNzw.data-ios-lock-scroll{z-index:20}.page_wonIj{min-height:100%;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LandingLayout": "LandingLayout_qEfn3",
	"pageWrapper": "pageWrapper_gnNzw",
	"fullHeight": "fullHeight_d61-+",
	"page": "page_wonIj"
};
module.exports = ___CSS_LOADER_EXPORT___;
