import Vue from 'vue';

export default ({ app }, inject) => {
  const contextMenu = {
    data: Vue.observable({
      show: false,
      options: {
        x: 0,
        y: 0,
        attach: null
      },
      heading: '',
      item: {},
      items: []
    }),
    event: new Vue(),
    open (component, data) {
      this.event.$emit('open', component, data);
    },
    close () {
      this.event.$emit('close');
    },
    change (prop, value) {
      // eslint-disable-next-line import/no-named-as-default-member
      Vue.set(this.data, prop, value);
    }
  };
  app.$contextMenu = contextMenu;
  inject('contextMenu', contextMenu);
};
