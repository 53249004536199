// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DefaultLayout_ajaY9{display:-webkit-box;display:-ms-flexbox;display:flex;min-height:100%}.DefaultLayout_ajaY9,.pageWrapper_2pwgF{position:relative;width:100%}.pageWrapper_2pwgF{padding-top:var(--header-h);-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}.pageWrapper_2pwgF.fullHeight_zbjRx{height:100%}.pageWrapper_2pwgF.data-ios-lock-scroll{z-index:20}.page_idzqv{min-height:100%;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DefaultLayout": "DefaultLayout_ajaY9",
	"pageWrapper": "pageWrapper_2pwgF",
	"fullHeight": "fullHeight_zbjRx",
	"page": "page_idzqv"
};
module.exports = ___CSS_LOADER_EXPORT___;
