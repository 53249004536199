import { defineStore } from 'pinia';
import {
  computed,
  ref,
  useContext
} from '@nuxtjs/composition-api';
import { Image } from '@/types/Image';

interface MenuItem {
  id: number;
  name: string;
  link: string;
  icon: Image;
  hideDesktop: boolean;
}

interface MenuResult {
  top: Array<MenuItem>;
  right: Array<MenuItem>;
}

interface GetMenuResponse {
  result: MenuResult;
}

const excludeUrlByAggregator = ['/loyalty-program'];

const filteredMenu = (menu: Array<MenuItem>, isAggregator: boolean): Array<MenuItem> => {
  return menu?.filter((item) => isAggregator
    ? !excludeUrlByAggregator.includes(item.link)
    : true);
};

export const useMenuStore = defineStore('menu', () => {
  const {
    $auth,
    $sentry,
    $axios
  } = useContext();

  const menu = ref<MenuResult>({
    top: [],
    right: []
  });

  const headerMenu = computed(() => {
    const isAggregator = $auth.user.agency?.generalType === 'aggregator';

    return filteredMenu(menu.value.top, isAggregator);
  });

  const burgerMenu = computed(() => {
    const isAggregator = $auth.user.agency?.generalType === 'aggregator';

    return filteredMenu(menu.value.right, isAggregator);
  });

  async function getMenu (): Promise<void> {
    try {
      const {
        result
      } = await $axios.$get<GetMenuResponse>('api/v2/menu', {
        params: {
          city: 'tmn',
          menu_type: 'lk_broker'
        }
      });
      menu.value = result;
    } catch (error) {
      console.error('🚀 ~ file: menu.ts ~ getMenu ~ e', error);
      $sentry.captureException(error);
    }
  }

  return {
    getMenu,
    menu,
    headerMenu,
    burgerMenu
  };
});
