const newsApi = {
  news: {
    tags: '/api/news/tags',
    all: '/api/news',
    getNewsItem: (slug) => `/api/news/${ slug }`,
    getMarkBenefit: (slug) => `/api/news/${ slug }/mark_benefit`
  }
};

export default newsApi;
